/** Auto-generated file */
import { inject, Injectable } from '@angular/core';
import { EntityQueryDto, FilterQueryDto, FindOptionsDto } from '@nest/common/core/shared';
import { ApiService } from '@nest/common/frontend/core';
import { PricelistDto, PricelistItemDto } from '@nest/domain/entities-metadata/dtos';

@Injectable({
	providedIn: 'root'
})
export class PricelistDataAccessService {
	private api = inject(ApiService);

	// GET @ pricelists/
	getAll(query: EntityQueryDto<PricelistDto>) {
		return this.api.post<PricelistDto[]>(`Pricelist.getAll`, { body: { data: query } });
	}

	// GET @ pricelists/:id
	getById(id: number, query: FindOptionsDto<PricelistDto>) {
		return this.api.post<PricelistDto>(`Pricelist.getById`, { body: { data: { id, fields: query?.fields } } });
	}

	// GET @ pricelists/:id/items
	getPricelistItems(id: number, query: EntityQueryDto<PricelistItemDto>) {
		return this.api.get<PricelistItemDto[]>(`pricelists/${id}/items`, query);
	}

	count(query: FilterQueryDto<PricelistDto>) {
		return this.api.post<number>(`Pricelist.count`, { body: { data: query } });
	}

	// GET @ pricelists/items
	getItems(query: EntityQueryDto<PricelistItemDto>) {
		return this.api.get<PricelistItemDto[]>(`pricelists/items`, query);
	}

	// POST @ pricelists/:id?
	save(id: number, body: PricelistDto) {
		return this.api.post<PricelistDto>(`pricelists/${id}?`, { body });
	}

	// POST @ pricelists/:id/clear
	clearPrices(id: number) {
		return this.api.post<void>(`pricelists/${id}/clear`, {});
	}
}

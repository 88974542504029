/** Auto-generated file */
import { inject, Injectable } from '@angular/core';
import { EntityQueryDto, FindOptionsDto } from '@nest/common/core/shared';
import { ApiService } from '@nest/common/frontend/core';
import { PricelistRuleDto } from '@nest/domain/entities-metadata/dtos';

@Injectable({
	providedIn: 'root'
})
export class PricelistRuleDataAccessService {
	private api = inject(ApiService);

	// GET @ pricelists/products/rules/
	getAll(query: EntityQueryDto<PricelistRuleDto>) {
		return this.api.get<PricelistRuleDto[]>(`pricelists/products/rules/`, query);
	}

	// GET @ pricelists/products/rules/:id
	getById(param: any, query: FindOptionsDto<PricelistRuleDto>) {
		return this.api.get<PricelistRuleDto>(`pricelists/products/rules/${param.id}`, query);
	}

	// POST @ pricelists/products/rules/:id(\\d+)?
	save(param: any, body: PricelistRuleDto) {
		return this.api.post<PricelistRuleDto>(`pricelists/products/rules/${param.id}(\\d+)?`, { body });
	}
}
